
@mixin GPFont {
  font-family: GothamPro, sans-serif; }

.sec-checkout {
  max-width: none;
  background: var(--checkout-section-bg);
  font-family: var(--checkout-section-ff, GothamPro), sans-serif;
  color: var(--checkout-section-color);
  padding-bottom: 50px;

  &__title {
    margin: 0 auto 22px;
    letter-spacing: 0.01em;
    text-align: center;
    font-size: clamp(30px, 7vw, 48px);
    font-weight: 900;
    line-height: 1.0;
    text-transform: uppercase;
    @include sm {
      margin-bottom: 30px;
      margin-top: 0; } }
  &__description {
    margin-bottom: clamp(25px, 10vw, 40px);
    line-height: 1.6;
    font-size: clamp(12px, 2vw, 16px);
    text-align: center; }
  &__review {
    margin-bottom: 60px;
    @include sm {
      margin-bottom: 40px; } }
  .container {
    max-width: 1058px;
    padding-left: 28px;
    padding-right: 28px;
    @include sm {
      padding-left: 20px;
      padding-right: 20px; } } }

.checkout-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  height: 34px;
  border-radius: 100px;
  border: none;
  background: linear-gradient(87deg, #9F0 2.5%, #00DD3B 97.5%);
  color: #181818;
  font-size: 12px;
  font-weight: 500;
  font-family: GothamPro, sans-serif;
  text-transform: capitalize;
  transition: background 0.2s linear, color 0.2s linear;
  text-decoration: none;
  &:hover, &:focus-visible {
    background: linear-gradient(187deg, #9F0 2.5%, #00DD3B 97.5%);
    color: #181818;
    text-decoration: none; }
  &--disabled {
    background: #4E4E4E;
    color: #fff;
    &:hover, &:focus-visible {
      background: #5E5E5E;
      color: #fff; } }

  &--light {
    background: #fff;
    color: #FE5619;
    &:hover, &:focus-visible {
      background: #f1f1f1;
      color: #FE5619; } }
  &--primary {
    background: #181818;
    color: #fff;
    &:hover, &:focus-visible {
      background: #383838;
      color: #fff; } } }

.checkout-header {
  padding: 56px 0;
  border-bottom: 1px solid #4E4E4E;
  margin-bottom: 56px;
  @include md {
    padding: 32px 0;
    margin-bottom: 20px;
    border: none; }
  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 56px;
    @include md {
      flex-direction: column;
      gap: 48px; } }

  &__logo {
    display: block;
    flex: none;
    width: 100%;
    max-width: 190px;
    @include xs {
      max-width: 150px; }
    img {
      max-width: 100%;
      display: block; } } }

.checkout-price-increase {
  display: flex;
  align-items: flex-end;
  position: relative;
  @include sm {
    flex-direction: column;
    align-items: center;
    width: 100%; }
  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.1;
    padding-top: 18px;
    padding-right: 36px;
    position: relative;
    align-self: flex-start;
    @include sm {

      display: none; } }

  &__list {
    --_item-width: 100px;
    display: flex;
    align-items: flex-end;
    text-align: center;
    @include sm {
      position: relative; }
    @include xs {
      --_item-width: 90px; }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 34px;
      width: calc(100% - (var(--_item-width) /2));
      height: 2px;
      background: var(--accentColor);
      @include sm {
        right: calc(100% - var(--_item-width));
        left: auto;
        width: 800px; } }
    &-item {
      width: var(--_item-width);
      padding: 0 12px;
      position: relative;
      z-index: 1;
      @include sm {
        padding-left: 6px;
        padding-right: 6px; }

      &:before {
        content: '';
        position: absolute;
        bottom: 34px;
        left: calc(var(--_item-width) / -2);
        width: 100%;
        background: #4E4E4E;
        height: 2px; }
      &:after {
        content: '';
        position: absolute;
        left: calc(50% - 10px);
        bottom: 25px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #4E4E4E; }

      &:first-child {
        &:before {
          display: none; } }
      &.is-active {
        z-index: 2;
        &:after, &:before {
          background: var(--accentColor); } } }

    &-day {
      font-size: 12px;
      color: #949494;
      margin-bottom: 6px; }
    &-price {
      font-size: 20px;
      font-weight: 700;
      line-height: 1.1;
      white-space: nowrap; }

    &-name {
      font-size: 12px;
      color: #949494;
      margin-top: 36px; } } }

.checkout-container {
  display: grid;
  grid-template-columns: 1fr 262px;
  gap: 32px;
  @include md {
    gap: 24px; }
  @include sm {
    grid-template-columns: 1fr;
    gap: 48px; }
  &__side {
    order: 2;
    padding-top: 52px;

    @include sm {
      order: 0;
      padding-top: 0; } } }

.checkout-side {
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: sticky;
  top: 40px;
  max-height: calc(100vh - 130px);
  overflow: hidden;
  overflow-y: auto;
  padding-right: 10px;
  margin-right: -10px;

  @include sm {
    position: static;
    max-height: none;
    padding-right: 0;
    margin-right: 0; }
  &__title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 16px;
    line-height: 1.2; }
  &__text {
    font-size: 12px;
    line-height: 1.3;
    a {
      color: var(--accentColor);
      text-decoration: underline;
      &:hover {
        text-decoration: none; } }
    &--lg {
      font-size: 14px; } }

  &__check-list {
    font-size: 12px;
    line-height: 1.3;
    display: flex;
    flex-direction: column;
    gap: 8px;
    li {
      position: relative;
      padding-left: 20px;
      &:before {
        content: url('../img/check-list-icon.svg');
        position: absolute;
        left: 0;
        top: -1px;
        width: 16px;
        height: 16px; } } } }

.checkout-form {
  margin-top: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 100px;
  padding: 4px;
  border: 1px solid #3E3E3E;
  &:has(.checkout-form__input:valid) {
    .checkout-btn {
      background: linear-gradient(87deg, #9F0 2.5%, #00DD3B 97.5%);
      color: #181818;
      &:hover, &:focus-visible {
        background: linear-gradient(187deg, #9F0 2.5%, #00DD3B 97.5%); } } }
  &__field {
    flex: 1; }
  &__input {
    border-radius: 100px 0 0 100px;
    padding: 0 16px;
    height: 34px;
    border: none;
    background: transparent;
    font-size: 14px;
    color: #fff;
    width: 100%;

    &::placeholder {
      color: #C5C5C5; } }
  &__btn {
    min-width: 112px;
    font-size: 14px; } }

.addon-list {
  display: flex;
  flex-direction: column;
  gap: 10px; }

.addon-card {
  padding: 24px;
  border-radius: 24px;
  background: linear-gradient(87deg, #9F0 2.5%, #00DD3B 97.5%);
  color: #181818;
  position: relative;
  z-index: 1;
  overflow: hidden;
  &:after {
    content: '';
    background: url('../img/addon-card-bg-smile.svg') no-repeat center center;
    background-size: contain;
    position: absolute;
    bottom: -20px;
    right: -28px;
    width: 118px;
    height: 156px;
    opacity: 0.3;
    z-index: -1; }
  &__title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 12px;
    line-height: 1;
    letter-spacing: -0.02em; }
  &__text {
    font-size: 14px; }

  &__cta {
    margin-top: 16px; }

  &--multipass {
    background: #F60;
    color: #fff;
    &:after {
      opacity: 0.2; } } }

.checkout-tabs {
  display: flex;
  position: relative;
  margin-bottom: 25px;
  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0px;
    display: block;
    border-bottom: 2px solid #eee; }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding: 15px 28px;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    position: relative;
    z-index: 4;
    font-family: inherit;
    font-size: 18px;
    font-weight: 700;
    color: var(--checkout-card-text-color, #fff);
    background: transparent;
    border: none;
    @include sm {
      padding: 10px 5px;
      font-size: 14px;
      flex: 1; }
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -1px;
      height: 2px; }
    &.is-active {
      &:after {
        background: var(--accentColor); } } } }

.tito-wrapper {
  position: relative;
  z-index: 2;
  &__text {
    margin-top: 30px;
    line-height: 1.6;
    font-size: clamp(12px, 2vw, 16px);
    text-align: center;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    a {
      color: var(--accentColor);
      text-decoration: underline;
      &:hover {
        text-decoration: none; } } } }

.tito-widget {
  margin-top: 0;
  margin-bottom: 0;
  font-family: inherit;
  color: var(--checkout-card-text-color, #fff);

  .tito-widget-form {
    max-width: none;
    background: transparent;
    border: none;
    padding: 0;

    & > div {
      display: flex;
      flex-direction: column;
      gap: 16px;
      @include sm {
        gap: 8px; }
      &:not(:has(#other + section)) #other {
        display: none !important; } }

    // show continue button if choose ticket
    &:has(.tito-quantity > .tito-release--decrement-quantity:first-child > .tito-release--decrement-quantity--link:not(.tito-release--decrement-quantity--link--disabled)) {

      .tito-submit {
        display: block; } }

    &:has(.tito-discounted-from) {
      .tito-discount-btn {
        .icon-check {
          display: block; }
        .icon-send {
          display: none; } } }

    .tito-release {
      display: grid;
      gap: 32px;
      grid-template-columns: 1fr 160px;
      grid-template-rows: 1fr 1fr;
      padding: 34px;
      position: relative;
      z-index: 1;
      order: 90;
      border-radius: 24px;
      border: var(--checkout-card-border);
      background: var(--checkout-card-bg);
      @include md {
        grid-template-rows: auto 1fr;
        grid-template-columns: 1fr auto;
        margin-top: 0;
        gap: 24px 0;
        padding: 30px; }
      @include xxxs {
        padding: 25px;
        gap: 25px 0; }
      &.multipass-ticket {
        border-color: var(--accentColor) !important; }

      &[data-ticket-name="Combo tickets"] {
        order: 1; }
      &[data-ticket-name="Remote"] {
        order: 25; }
      &[data-ticket-name="Workshops"] {
        order: 35; }
      &[data-anchor="workshop-pass"] {
        order: 34; }
      &[data-ticket-name="Other"] {
        order: 45; }

      &:has(.tito-release--title > small) {
        @include rmin(1025) {
          grid-template-rows: 28px 1fr auto;
          .tito-release-description {
            grid-row: 1 / 4; }
          .tito-price-details-regular {
            grid-row: 2 / 3;
            justify-content: center !important; }
          .tito-quantity, .tito-release--status {
            grid-row: 3 / 4; } }
        @include md {
          grid-template-rows: auto 1fr;
          .tito-price-details-regular, .tito-quantity {
            grid-row: 2/3; } } }

      // choose ticket
      &:not(.tito-release--sold-out):not(:has(.tito-quantity > *:first-child > .tito-release--decrement-quantity--link--disabled)) {
        border-color: #818181; }

      &:first-child {
        margin-top: 0; }

      .tito-release--title {
        font-size: 24px;
        color: var(--checkout-card-text-color, #fff);
        font-weight: 700;
        line-height: 1.2;
        text-transform: capitalize;
        margin-bottom: 24px;
        letter-spacing: -0.02em;
        @include md {
          font-size: 20px; }

        .ticket-date {
          display: block;
          font-size: 14px;
          color: #949494;
          font-weight: 400;
          margin-top: 10px;
          letter-spacing: 0; }
        small {
          background: none;
          padding: 0;

          font-weight: 400;
          position: absolute;
          width: 160px;
          text-align: center;
          top: 34px;
          right: 34px;
          text-transform: capitalize;
          font-size: 14px;
          line-height: 1;
          letter-spacing: 0;
          @include md {
            top: auto;
            bottom: 80px;
            right: 30px;
            width: 144px;
            font-size: 12px; }
          @include xs {
            width: 124px;
            font-size: 10px;
            right: 25px; } } }
      .tito-release-description {
        padding: 0 32px 0 0;
        grid-row: 1 / 3;
        align-items: start;
        flex-direction: column;
        border-right: 1px solid #3E3E3E;
        @include md {
          min-width: 100%;
          grid-row: 1/2;
          grid-column: 1/3;
          padding-right: 0;
          border: none; }

        & > * {
          flex-basis: auto !important; }

        .tito-release-description--description {
          color: var(--checkout-card-text-color) !important;
          font-size: 14px;

          @include md {
            font-size: 12px; }
          & > *:first-child {
            margin-top: 0; }
          a {
            color: inherit;
            text-decoration: underline; }
          p {
            color: inherit;
            margin: 15px 0 0;
            font-size: inherit;
            &:empty {
              display: none; } }
          ul, ol {
            margin: 15px 0 0; }

          ul li {
            list-style-type: none;
            position: relative;
            padding-left: 12px;
            &:before {
              content: '–';
              position: absolute;
              left: 0;
              top: 0; } }
          ol li {
              list-style-type: decimal; } } }

      .tito-quantity {
        align-self: start;
        position: relative;
        align-items: stretch;
        width: 100%;
        border-radius: 100px;
        gap: 5px;
        border: var(--checkout-card-border);
        padding: 6px;
        @include md {
          width: 144px;
          align-self: flex-end; }
        @include xs {
          width: 124px; }

        .tito-quantity-input {

          box-shadow: none;
          height: 32px;
          color: #fff;
          flex: 1 1 auto;
          width: auto !important;
          min-width: 1px;
          line-height: 1 !important;
          font-size: 24px;
          font-family: var(--checkout-form-ff);
          background: transparent;
          border: none;
          @include sm {
            height: 28px;
            font-size: 20px; }
          &::placeholder {
            color: #fff; } }

        .tito-release--decrement-quantity,
        .tito-release--increment-quantity {
          & > a {
            height: 32px;
            width: 32px;
            background: var(--accentColor);
            border-radius: 50%;
            margin: 0;

            display: block;
            box-sizing: border-box;
            border: none !important;
            position: relative;
            @include sm {
              width: 28px;
              height: 28px; }
            svg {
              display: none; }
            &:after, &:before {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              margin-left: -1px;
              height: 14px;
              width: 2px;
              background: #232323;
              margin-top: -7px;
              pointer-events: none; }

            &:before {
              transform: rotate(90deg); }
            &:hover, &:focus-visible {
              background: #181818;
              border: #181818 !important;

              &:after, &:before {
                background: #fff; } } }

          &:first-child > a {
            &:after {
              display: none; } } } }

      .tito-release--status {
        background: #4E4E4E;
        border: none;
        align-self: center;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: capitalize;
        font-size: 14px;
        border-radius: 100px;
        height: 42px;
        margin-right: 0;
        margin-left: 0;
        letter-spacing: 0;
        color: var(--checkout-card-text-color, #fff); }

      .tito-price-details-regular {
        font-size: 40px;
        line-height: 1;
        text-align: center;
        justify-content: flex-end;
        font-weight: 700;
        color: var(--checkout-card-text-color, #fff);

        @include md {
          grid-row: auto;
          font-size: 32px;
          justify-content: center;
          text-align: left; }

        & > div {
          width: 100%; }

        .tito-release--tax {
          text-align: center;
          margin-top: 10px;
          font-size: 10px;
          color: #A8A8A8;
          @include md {
            text-align: left;
            margin-top: 5px; }
          @include sm {
            .tito-release--tax {
              font-size: 9px; } } }
        .tito-price {
          text-align: center;
          @include md {
            text-align: left; } }

        .tito-discounted-from {
          font-size: 24px;
          font-weight: 700;
          color: #A7A7A7;
          display: block;
          margin: 0;
          @include md {
            font-size: 16px; } }
        .tito-discounted-price {

          font-weight: 700;
          color: var(--accentColor);
          text-transform: capitalize;
          margin-top: 10px;
          display: block;
          @include md {
            margin-top: 5px; } } } }

    .tito-release--sold-out {
      order: 100;

      .tito-release--title, tito-release-description--description, .tito-price,
      .tito-price-details-regular .tito-release--tax {
        opacity: 0.3; }
      .tito-release--title {
        margin-bottom: 0;
        font-weight: 400;
        text-decoration: line-through; }

      .tito-release-description--description {
        display: none; } }

    .tito-times {
      display: none; }

    .multipass-block {
      order: 24;
      display: grid; }

    &:not(:has(#combo-tickets)) {
      .tito-section#remote {
        margin-top: 0; }
      &:not(:has(#remote)) {
        .tito-section#workshops {
          margin-top: 0; } } } }

  .tito-form-actions {
    align-items: center;
    padding: 12px 28px;
    order: 101;
    z-index: 30;
    position: fixed;
    width: 100%;
    max-width: 1058px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    flex-wrap: nowrap;
    gap: 10px;
    @include sm {
      padding-left: 20px;
      padding-right: 20px; }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      z-index: -1;
      width: 150vw;
      left: -50vw;
      background: rgba(24, 24, 24, 0.50);
      border-top: 1px solid #3E3E3E;
      backdrop-filter: blur(4px);
      pointer-events: none; }
    &:has(input[type=text]:placeholder-shown) {
      .tito-discount-btn {
        background: #4E4E4E;
        color: rgba(0, 0, 0, 1);
        cursor: default; } }

    input[type=text] {
      height: 48px;
      padding: 0 20px !important;
      font-size: 12px;
      font-family: GothamPro, sans-serif;
      color: var(--checkout-form-color);
      box-shadow: none;
      background: #262626 !important;
      border-radius: 100px;
      border: 1px solid #3E3E3E;
      width: 100% !important;

      &::placeholder {
        color: #999; }
      &:focus-visible {
        outline: 2px solid rgba($white, 0.4); } }

    .tito-submit:not(.tito-submit--discount-code) {
      display: none; }
    .tito-submit, .tito-submit.tito-submit--discount-code {

      height: 48px;
      width: 144px;
      border: none !important;
      max-width: none;
      background: var(--checkout-button-background);
      color: var(--checkout-button-color);
      margin-left: auto;
      font-family: GothamPro, sans-serif;
      padding: 0 30px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      border-radius: 100px;
      text-transform: capitalize;
      transition: color 0.2s linear, background 0.2s linear;
      flex: none;
      &.tito-submit--discount-code {
        display: none !important; }
      &:hover, &:focus-visible {
        color: var(--checkout-button-color-hover);
        background: var(--checkout-button-background-hover); }

      @include sm {
        width: 128px; } }

    .tito-discount {
      min-width: auto;
      width: 214px;
      margin-bottom: 0;
      flex: none !important;
      position: relative;
      @include sm {
        width: 184px; }
      &-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 4px;
        right: 4px;
        width: 40px;
        height: 40px;
        font-size: 12px;
        font-weight: 700;
        border: none;
        background: var(--checkout-button-background);
        border-radius: 100px;
        color: var(--checkout-button-color);
        &:hover, &:focus-visible {
          background: var(--checkout-button-background-hover);
          color: var(--checkout-button-color-hover); }
        .icon-check {
          display: none; } } } } }

.tito-overlay {
  a {
    color: inherit; } }

.tito-block._promocode {
  .tito-discount {
    display: block; } }

.checkout-tab {
  display: none;
  &.is-active {
    display: block; } }

.tito-locked-ticket-message {
  order: -1; }

.tito-section {
  position: relative;
  margin-top: 24px;
  @include sm {
    margin-bottom: 8px; }

  &#combo-tickets {
    order: 1;
    margin-top: 0; }

  &#remote {
    order: 20; }
  &#workshops {
    order: 30; }
  &#other {
    order: 40; }

  &__title {
    font-size: 32px;
    @include md {
      font-size: 24px; }
    @include sm {
      text-align: center; } } }

@include md {
  .hidden-iphone {
    display: none !important; } }

.multipass-block {
  display: none;
  gap: 32px;
  align-items: center;
  grid-template-columns: 1fr 160px;
  padding: 34px;
  border-radius: 24px;
  background: var(--checkout-card-bg);
  border: var(--checkout-card-border);
  @include md {
    grid-template-rows: auto 1fr;
    grid-template-columns: auto;
    margin-top: 0;
    gap: 40px 0;
    padding: 30px; }
  @include xxxs {
    padding: 25px;
    gap: 25px 0; }

  &__content {
    border-right: var(--checkout-card-border);
    padding-right: 32px;
    @include md {
      border: none;
      padding-right: 0; } }

  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.3;
    text-transform: capitalize;
    margin-bottom: 24px;
    @include md {
      font-size: 20px; }
    @include xs {
      font-size: 16px; }
    @include xxxs {
      margin-bottom: 15px; } }

  &__logos {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 30px;
    align-items: center;
    @include md {
      grid-template-columns: repeat(4, 1fr );
      gap: 24px 16px; }
    @include xxs {
      gap: 20px 16px;
      grid-template-columns: repeat(3, 1fr ); }
    img {
      max-width: 100%;
      display: block;
      max-height: 50px;
      margin-left: auto;
      margin-right: auto; } }

  &__price {
    font-size: 40px;
    font-weight: 700;
    color: var(--checkout-card-text-color, #fff);
    line-height: .8;
    letter-spacing: 0.5px;
    white-space: nowrap;
    display: flex;
    align-items: flex-end;
    @include md {
      font-size: 32px; }

    @include xxxs {
      font-size: 26px; }
    span {
      font-size: 45%;
      letter-spacing: 0.2px;
      font-weight: 400;
      display: inline; } }

  &__team {
    font-size: 18px;
    font-weight: 400;
    margin-top: 10px;
    @include md {
      font-size: 18px; }
    @include xxs {
      font-size: 16px; } }

  &__text {
    margin-top: 15px;

    ul {
      font-size: 12px;
      display: flex;
      flex-direction: column;
      gap: 8px;

      li:before {
        content: "+";
        margin-right: 5px; } } }

  &__cta {
    margin-top: 20px;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 100%;
      height: 42px;
      max-width: 300px;
      color: var(--checkout-section-color, #fff);
      background: transparent;
      text-decoration: none;
      border: 1px solid #F60;
      border-radius: 100px;
      font-size: 12px;
      line-height: 1;
      font-weight: 700;
      text-transform: capitalize;
      transition: color 0.2s linear, background 0.2s linear;
      white-space: nowrap;
      padding: 0 20px;
      @include xs {
        max-width: none; }
      &:hover, &:focus-visible {
        background: #F60;
        color: #fff; } } } }
.с-review {
  max-width: 780px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.2;

  &__text {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    line-height: 1.2;
    @include sm {
      font-size: 14px; }
    a {
      color: #230CEA;
      &:hover {
        text-decoration: underline; } } }
  &__author {
    margin-top: 30px;
    display: flex;
    align-items: center;
    gap: 20px;
    margin-left: auto;
    margin-right: auto;
    max-width: max-content;
    @include sm {
      margin-top: 20px;
      gap: 10px; }
    &-img {
      width: 56px;
      height: 56px;
      @include sm {
        width: 48px;
        height: 48px; }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%; } }
    &-content {
      max-width: 150px;
      text-align: left; }
    &-name {
      font-size: 14px;
      font-weight: 600; }

    &-company {
      font-size: 12px;
      color: #A0A0A0;
      margin-top: 5px; } } }

a.tito-spoiler-toggle {

  display: flex;
  width: max-content;
  gap: 4px;
  align-items: center;
  justify-content: center;
  padding: 10px 12px 8px;
  margin-top: 15px;
  font-size: 10px;
  line-height: 1;
  font-weight: 500;
  text-decoration: none !important;
  border: var(--checkout-spoiler-toggle-border);
  border-radius: 100px;
  transition: background 0.2s linear;

  &:hover, &:focus-visible, &.is-active {
    background: var(--checkout-spoiler-toggle-background-hover);
    text-decoration: none; }
  &.is-active {
    svg {
      transform: rotate(180deg); } }
  svg {
    fill: var(--accentColor);
    display: block;
    flex: none;
    width: 8px;
    top: -1px;
    position: relative; } }
